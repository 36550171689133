import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_calendar = _resolveComponent("tm-calendar")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "calendarModal",
    size: "xLarge",
    "modal-title": "Preview in calendar"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_tm_calendar)
    ]),
    _: 1
  }))
}